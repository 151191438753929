import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import HomeLink from '../components/HomeLink'
import Overlay from '../components/overlay/Overlay'
import Menu from '../components/menu'
import Link from '../components/Link'
import CompanyInfo from '../components/CompanyInfo'
import ReviewLinks from '../components/ReviewLinks'
import Copyright from '../components/Copyright'

import favicon from '../../static/icons/favicon.png'

import '../styles/pages/404-page/index.scss'

const linkProps = {
  outlined: true
}

export default () => {
  const breakpoint = useBreakpoint()

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title> { 'Not found page' } </title>
        <link rel="icon" href={ favicon } />
        <style>
          {
            `
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-display: swap;
              src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3g3D_u50.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-display: swap;
              src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-display: swap;
              src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-display: swap;
              src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 700;
              font-display: swap;
              src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 700;
              font-display: swap;
              src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 900;
              font-display: swap;
              src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3g3D_u50.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 900;
              font-display: swap;
              src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3gnD_g.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            `
          }
        </style>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <script src="https://www.googletagmanager.com/gtag/js?id=G-HJNNRGFQPQ" defer />
        <script src={ withPrefix('scripts/google-analytics.js') } type="text/javascript" defer />
        <script src="https://analytics.ahrefs.com/analytics.js" data-key="9hGBVSbXDRHfDdKqsbLSAQ" defer="true"></script>
      </Helmet>
      <div className="not-found-page">
        <HomeLink />

        <div className="not-found-page__text">
          <h1>404</h1>
          <h2>Sorry about that, we couldn't find the page you were looking for.</h2>
          <Link
            to="/"
            {...linkProps}
          >
            Go to homepage
          </Link>
        </div>

        { !breakpoint.md && (
          <div>
            <CompanyInfo />
            <ReviewLinks />
          </div>
        ) }

        <Overlay />
        <Menu />
        <Copyright />
      </div>
    </>
  )
}

